/* eslint-disable no-unused-vars */
const tagsExclusinCases = {
  tagPump1: [
    {
      key: 'Training',
      value: 'Pump 1',
    },
  ],
  tagPump2: [
    {
      key: 'Training',
      value: 'Pump 2',
    },
  ],
  tagPump3: [
    {
      key: 'Training',
      value: 'Pump 3',
    },
  ],
  tagPump4: [
    {
      key: 'Training',
      value: 'Pump 4',
    },
  ],
  tagSmartGuard: [
    {
      key: 'Training',
      value: 'SmartGuard',
    },
  ],
  tagMinimed: [
    {
      key: 'Training',
      value: 'Full System Upgrade',
    },
  ],
  tagMinimedPumpAndOnly: [
    {
      key: 'Training',
      value: 'Pump and CGM',
    },
  ],
  tagCarelinkPumpOnly: [
    {
      key: 'Training',
      value: 'Carelink pump only',
    },
  ],
  tagCarelinkPumpandCGM: [
    {
      key: 'Training',
      value: 'Carelink pump and CGM',
    },
  ],
  tag2CGM1: [
    {
      key: 'Training 2',
      value: 'CGM 1',
    },
  ],
  tag2CGM2: [
    {
      key: 'Training 2',
      value: 'CGM 2',
    },
  ],
  tag2CGM3: [
    {
      key: 'Training 2',
      value: 'CGM 3',
    },
  ],
  tag2CGM4: [
    {
      key: 'Training 2',
      value: 'CGM 4',
    },
  ],
  tagSimplera: [
    {
      key: 'Training',
      value: 'Simplera Sync',
    },
  ],
  tagGuardian4: [
    {
      key: 'Training',
      value: 'Guardian 4',
    },
  ],
};

export function getNextTag(tags, last_training_registered_to, initial_tags) {
  if (
    last_training_registered_to === null ||
    last_training_registered_to === undefined
  ) {
    //this is from 1st to 2nd tag
    let obj = tags.find((o) => o.value === 'Adult' || o.value === 'Child');

    if (tags.some((e) => e.key === 'Training' && e.value === 'Pump 1')) {
      return [...tagsExclusinCases.tag2CGM1, obj];
    } else if (tags.some((e) => e.key === 'Training' && e.value === 'Pump 2')) {
      return [...tagsExclusinCases.tag2CGM2, obj];
    } else if (tags.some((e) => e.key === 'Training' && e.value === 'Pump 3')) {
      return [...tagsExclusinCases.tag2CGM3, obj];
    } else if (tags.some((e) => e.key === 'Training' && e.value === 'Pump 4')) {
      return [...tagsExclusinCases.tag2CGM4, obj];
    } else {
      return [];
    }
  } else {
    return [];
  }
}

const NlTagSystem = {
  'cvent.steps.boxes': {
    'cvent.cards.first_step.title': {
      key: 'Training',
      value: 'Adult',
    },
    'cvent.cards.second_step.title': {
      key: 'Training',
      value: 'Child',
    },
    'cvent.cards.third_step.title': {
      key: 'Training',
      value: 'Get Connected Adult',
    },
    'cvent.cards.fourth_step.title': {
      key: 'Training',
      value: 'Get Connected Child',
    },
  },
  'cvent.steps.gb.entry_level_title': {
    'cvent.steps.gb.minimed_pump': {
      key: 'Pump Experience',
      value: 'MM640G',
    },
    'cvent.steps.gb.pump_only': {
      key: 'Therapy',
      value: 'Pump only',
    },
    'cvent.steps.gb.pump_and_cgm': {
      key: 'Therapy',
      value: 'Pump & CGM',
    },
    'cvent.steps.gb.cgm_for_existing_pump': {
      key: 'Therapy',
      value: 'CGM',
    },
    'cvent.steps.gb.cgm_and_smartguard': {
      key: 'Training',
      value: 'CGM & Smartguard',
    },
    'cvent.steps.gb.smartguard': {
      key: 'Training',
      value: 'Smartguard',
    },
    'cvent.steps.gb.carelink': {
      key: 'Training',
      value: 'Carelink',
    },
    'cvent.steps.gb.minimed': {
      key: 'Training',
      value: 'Full System Upgrade',
    },
    'cvent.steps.gb.minimed_pump_and_cgm': {
      key: 'Training',
      value: 'Pump and CGM',
    },
  },
  'cvent.steps.gb.select_product': {
    'cvent.steps.gb.mm_640g': {
      key: 'Product',
      value: 'MM640G',
    },
    'cvent.steps.gb.mm_740g': {
      key: 'Product',
      value: 'MM740G',
    },
    'cvent.steps.gb.mm_780g': {
      key: 'Product',
      value: 'MM780G',
    },
    'cvent.steps.gb.simplera_sync': {
      key: 'Training',
      value: 'Simplera Sync',
    },
    'cvent.steps.gb.guardian_4': {
      key: 'Training',
      value: 'Guardian 4',
    },
    'cvent.steps.gb.prod_pump_only': {
      key: 'Product',
      value: 'Pump only',
    },
    'cvent.steps.gb.prod_pump_and_cgm': {
      key: 'Product',
      value: 'Pump and CGM',
    },
  },
  'cvent.steps.cvent_select_session_title': {
    'cvent.steps.gb.one_session': {
      key: 'Number of Training',
      value: '1',
    },
    'cvent.steps.gb.two_session': {
      key: 'Number of Training',
      value: '2',
    },
  },
  'cvent.steps.gb.insulin_pump_experience': {
    'cvent.steps.gb.mm_640g': {
      key: 'Pump Experience',
      value: 'MM640G',
    },
    'cvent.steps.gb.mm_670g': {
      key: 'Pump Experience',
      value: 'MM670G',
    },
    'cvent.steps.gb.mm_780g': {
      key: 'Pump Experience',
      value: 'MM780G',
    },
    'cvent.steps.gb.simplera_sync': {
      key: 'Training',
      value: 'Simplera Sync',
    },
    'cvent.steps.gb.guardian_4': {
      key: 'Training',
      value: 'Guardian 4',
    },
    'cvent.steps.gb.other': {
      key: 'Pump Experience',
      value: 'Other',
    },
    'cvent.steps.gb.no_experience': {
      key: 'Pump Experience',
      value: 'No Experience',
    },
  },
  'cvent.steps.gb.sensor_experience': {
    'cvent.steps.gb.exp_with_mdt_sensor': {
      key: 'Sensor Experience',
      value: 'MDT Experience',
    },
    'cvent.steps.gb.exp_with_other_cgm_sensor': {
      key: 'Sensor Experience',
      value: 'Other Experience',
    },
    'cvent.steps.gb.no_sensor_exp': {
      key: 'Sensor Experience',
      value: 'No Experience',
    },
  },
};

export function getInitialTags(summary, form) {
  const finalTags = [];

  for (const choice of summary) {
    if (NlTagSystem[choice.stepName]) {
      finalTags.push(NlTagSystem[choice.stepName][choice.option.title]);
    }
  }

  return finalTags;
}

export function flow(summary, form) {
  const finalTags = [];
  const finalTagsEdit = [];

  for (const choice of summary) {
    if (NlTagSystem[choice.stepName]) {
      finalTags.push(NlTagSystem[choice.stepName][choice.option.title]);
    }
  }

  if (finalTags.some((e) => e.key === 'Training' && e.value === 'Smartguard')) {
    finalTagsEdit.push(...tagsExclusinCases.tagSmartGuard);
  } else if (
    finalTags.some((e) => e.key === 'Training' && e.value === '780G')
  ) {
    finalTagsEdit.push(...tagsExclusinCases.tagMinimed);
  } else if (
    finalTags.some((e) => e.key === 'Training' && e.value === 'Carelink') &&
    finalTags.some((e) => e.key === 'Product' && e.value === 'Pump only')
  ) {
    finalTagsEdit.push(...tagsExclusinCases.tagCarelinkPumpOnly);
  } else if (
    finalTags.some((e) => e.key === 'Training' && e.value === 'Carelink') &&
    finalTags.some((e) => e.key === 'Product' && e.value === 'Pump and CGM')
  ) {
    finalTagsEdit.push(...tagsExclusinCases.tagCarelinkPumpandCGM);
  } else if (
    finalTags.some((e) => e.key === 'Therapy' && e.value === 'Pump & CGM') &&
    finalTags.some((e) => e.key === 'Number of Training' && e.value === '2') &&
    finalTags.some(
      (e) => e.key === 'Pump Experience' && e.value === 'No Experience'
    ) &&
    finalTags.some(
      (e) => e.key === 'Sensor Experience' && e.value === 'No Experience'
    )
  ) {
    finalTagsEdit.push(...tagsExclusinCases.tagPump1);
  } else if (
    finalTags.some((e) => e.key === 'Therapy' && e.value === 'Pump & CGM') &&
    finalTags.some((e) => e.key === 'Number of Training' && e.value === '2') &&
    finalTags.some(
      (e) =>
        e.key === 'Pump Experience' &&
        (e.value === 'MM640G' || e.value === 'Other')
    ) &&
    finalTags.some(
      (e) =>
        e.key === 'Sensor Experience' &&
        (e.value === 'MDT Experience' || e.value === 'Other Experience')
    )
  ) {
    finalTagsEdit.push(...tagsExclusinCases.tagPump2);
  } else if (
    finalTags.some((e) => e.key === 'Therapy' && e.value === 'Pump & CGM') &&
    finalTags.some((e) => e.key === 'Number of Training' && e.value === '2') &&
    finalTags.some(
      (e) =>
        e.key === 'Pump Experience' &&
        (e.value === 'MM640G' || e.value === 'Other')
    ) &&
    finalTags.some(
      (e) => e.key === 'Sensor Experience' && e.value === 'No Experience'
    )
  ) {
    finalTagsEdit.push(...tagsExclusinCases.tagPump3);
  } else if (
    finalTags.some((e) => e.key === 'Therapy' && e.value === 'Pump & CGM') &&
    finalTags.some((e) => e.key === 'Number of Training' && e.value === '2') &&
    finalTags.some(
      (e) => e.key === 'Pump Experience' && e.value === 'No Experience'
    ) &&
    finalTags.some(
      (e) =>
        e.key === 'Sensor Experience' &&
        (e.value === 'MDT Experience' || e.value === 'Other Experience')
    )
  ) {
    finalTagsEdit.push(...tagsExclusinCases.tagPump4);
  }

  if (
    finalTags.some(
      (e) => e.key === 'Training' && e.value === 'CGM & Smartguard'
    )
  ) {
    finalTagsEdit.push({ key: 'Training', value: 'CGM & Smartguard' });
  } else if (
    finalTags.some(
      (e) => e.key === 'Training' && e.value === 'Full System Upgrade'
    )
  ) {
    finalTagsEdit.push({ key: 'Training', value: 'Full System Upgrade' });
  } else if (
    finalTags.some((e) => e.key === 'Training' && e.value === 'Pump and CGM')
  ) {
    finalTagsEdit.push({ key: 'Training', value: 'Pump and CGM' });
  }

  if (
    finalTags.some((e) => e.key === 'Training' && e.value === 'Simplera Sync')
  ) {
    finalTagsEdit.push({ key: 'Training', value: 'Simplera Sync' });
  } else if (
    finalTags.some((e) => e.key === 'Training' && e.value === 'Guardian 4')
  ) {
    finalTagsEdit.push({ key: 'Training', value: 'Guardian 4' });
  }

  if (finalTags.some((e) => e.key === 'Training' && e.value === 'Adult')) {
    finalTagsEdit.push({ key: 'Training', value: 'Adult' });
  } else if (
    finalTags.some((e) => e.key === 'Training' && e.value === 'Child')
  ) {
    finalTagsEdit.push({ key: 'Training', value: 'Child' });
  } else if (
    finalTags.some(
      (e) => e.key === 'Training' && e.value === 'Get Connected Adult'
    )
  ) {
    finalTagsEdit.push({ key: 'Training', value: 'Get Connected Adult' });
  } else if (
    finalTags.some(
      (e) => e.key === 'Training' && e.value === 'Get Connected Child'
    )
  ) {
    finalTagsEdit.push({ key: 'Training', value: 'Get Connected Child' });
  }

  return finalTagsEdit;
}
